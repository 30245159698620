import React from "react";
import { DotButton, useDotButton } from "./PlansCarouselDots";
import useEmblaCarousel from "embla-carousel-react";
import emblaStyles from "./style.module.scss";
import SharedButton from "@components/endurancewarrantyprotect/sharedButton";
import TicIcon from "../icons/ticIcon";
import styles from "../styles.module.scss";
import { BenefitsListItem } from "..";
const PlansCarousel = ({ slides }: { slides: BenefitsListItem[] }) => {
    const [emblaRef, emblaApi] = useEmblaCarousel({ loop: true });

    const { selectedIndex, scrollSnaps, onDotButtonClick } =
        useDotButton(emblaApi);

    return (
        <section className={emblaStyles["embla"]}>
            <div className={emblaStyles["embla__viewport"]} ref={emblaRef}>
                <div className={emblaStyles["embla__container"]}>
                    {slides.map((benefitItem, index) => (
                        <div
                            className={emblaStyles["embla__slide"]}
                            key={index}
                        >
                            <div
                                className={emblaStyles["embla__slide__number"]}
                            >
                                <div className={styles["item"]}>
                                    <h3
                                        className="text-[28px] font-bold"
                                        style={{ color: "#00445F" }}
                                    >
                                        {benefitItem.title}
                                    </h3>
                                    <p
                                        className="text-base"
                                        style={{ color: "#00445F" }}
                                    >
                                        {benefitItem.description}
                                    </p>
                                    <div className={styles["featuresStyle"]}>
                                        <ul className="">
                                            {benefitItem.list.map(
                                                (item, index) => (
                                                    <li key={index}>
                                                        <span>
                                                            <TicIcon />
                                                        </span>
                                                        <span
                                                            className=" text-sm lg:text-base font-light"
                                                            style={{
                                                                color: "#00445F",
                                                            }}
                                                        >
                                                            {item}
                                                        </span>
                                                    </li>
                                                ),
                                            )}
                                        </ul>
                                        {benefitItem.list2 && (
                                            <ul>
                                                {benefitItem.list2.map(
                                                    (item, index) => (
                                                        <li key={index}>
                                                            <span>
                                                                <TicIcon />
                                                            </span>
                                                            <span
                                                                className="text-sm lg:text-base font-light"
                                                                style={{
                                                                    color: "#00445F",
                                                                }}
                                                            >
                                                                {item}
                                                            </span>
                                                        </li>
                                                    ),
                                                )}
                                            </ul>
                                        )}
                                    </div>
                                    <div className={styles["buttonStyle"]}>
                                        <SharedButton
                                            buttonClassName="lg:py-3 py-2  w-full"
                                            openCoverageModal
                                            activePlan={benefitItem.slug}
                                        >
                                            More Details
                                        </SharedButton>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>

            <div className={emblaStyles["embla__dots"]}>
                {scrollSnaps.map((_, index) => (
                    <DotButton
                        key={index}
                        onClick={() => onDotButtonClick(index)}
                        className={emblaStyles["embla__dot"].concat(
                            index === selectedIndex
                                ? ` ${emblaStyles["embla__dot--selected"]}`
                                : "",
                        )}
                    />
                ))}
            </div>
        </section>
    );
};

export default PlansCarousel;

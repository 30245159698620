import React, { useState } from "react";

import styles from "./styles.module.scss";
import Card from "./Card";

import mainImage from "./images/main.png";

import features from "./features";
import PlusIcon from "@page-builder/components/faq/icons/PlusIcon";

function CarFeatures() {
    const [activeIndex, setActiveIndex] = useState(0);

    return (
        <div className={styles.container}>
            <h2 className={styles.title}>
                Breakdowns can strike at any time. Are you ready?
            </h2>
            <p className={styles.description}>
                Choose a vehicle part from below to see the average repair costs
                for each part.
            </p>
            <div className={styles.content}>
                <div className={styles.cards}>
                    <Card
                        key={features[activeIndex].title}
                        feature={features[activeIndex]}
                    />
                </div>
                <div className={styles.mainImageContainer}>
                    {features.map((feature) => (
                        <button
                            key={feature.title}
                            style={{
                                ...feature.position,
                            }}
                            onClick={() =>
                                setActiveIndex(features.indexOf(feature))
                            }
                            className={
                                activeIndex === features.indexOf(feature)
                                    ? styles.active
                                    : ""
                            }
                        >
                            <PlusIcon />
                        </button>
                    ))}
                    <img src={mainImage.src} alt="car features" />
                </div>
            </div>
        </div>
    );
}

export default CarFeatures;

import ac from "./images/ac.png";
import waterPump from "./images/water-pump.png";
import suspension from "./images/suspension.png";
import engine from "./images/engine.png";
import transmission from "./images/transmission.png";
import instrumentCluster from "./images/instrument-cluster.png";
import sunroof from "./images/sunroof.png";
import backup from "./images/backup-sensors.png";

export interface IFeature {
    position: Partial<{
        top: string;
        left: string;
        right: string;
        bottom: string;
    }>;
    title: string;
    description: string;
    image: string;
    price: string;
}

export default [
    {
        position: {
            top: "24%",
            left: "24%",
        },

        title: "A/C Compressor",
        description: "Average Out of Pocket Cost without Endurance.",
        image: ac.src,
        price: "$200-$300",
    },
    {
        position: {
            top: "41%",
            left: "8%",
        },

        title: "Engine",
        description: "Average Out of Pocket Cost without Endurance.",
        image: engine.src,
        price: "$2000-$3000",
    },
    {
        position: {
            top: "62%",
            left: "29%",
        },

        title: "Water Pump",
        description: "Average Out of Pocket Cost without Endurance.",
        image: waterPump.src,
        price: "$160-$600",
    },
    {
        position: {
            top: "74%",
            left: "45%",
        },

        title: "Suspension Struts",
        description: "Average Out of Pocket Cost without Endurance.",
        image: suspension.src,
        price: "$400-$1000",
    },
    {
        position: {
            top: "20%",
            left: "48%",
        },

        title: "Instrument Cluster",
        description: "Average Out of Pocket Cost without Endurance.",
        image: instrumentCluster.src,
        price: "$800-$1200",
    },
    {
        position: {
            top: "45%",
            left: "69%",
        },

        title: "Transmission",
        description: "Average Out of Pocket Cost without Endurance.",
        image: transmission.src,
        price: "$500-$1000",
    },
    {
        position: {
            top: "7%",
            left: "74%",
        },

        title: "Sunroof Motor",
        description: "Average Out of Pocket Cost without Endurance.",
        image: sunroof.src,
        price: "$115-$500",
    },
    {
        position: {
            top: "36%",
            right: "3%",
        },

        title: "Backup Sensors",
        description: "Average Out of Pocket Cost without Endurance.",
        image: backup.src,
        price: "$200-$400",
    },
] as IFeature[];

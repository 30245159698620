import * as React from "react";

const NonTicIcon = () => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="3"
        fill="none"
        viewBox="0 0 16 3"
    >
        <rect width="16" height="3" fill="#858585" rx="1.5"></rect>
    </svg>
);

export default NonTicIcon;

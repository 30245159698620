import React, { useCallback, useEffect, useRef, useState } from "react";
import { EmblaCarouselType, EmblaEventType } from "embla-carousel";
import useEmblaCarousel from "embla-carousel-react";
import {
    NextButton,
    PrevButton,
    usePrevNextButtons,
} from "./EmblaCarouselArrowButtons";
import emblaStyles from "./css/embla.module.scss";
import CustomerReview, { IUserReviewCardProps } from "../customerReviews/Card";

const reviews: IUserReviewCardProps[] = [
    {
        review: "“I'm honestly blown away by Endurance. My transmission went out, totally unexpected, and they took care of the whole thing. No back and forth, no drama. Just covered it. Their team was super easy to talk to felt like they actually cared. Couldn't be happier with my choice.”",
        author: "James R.",
    },
    {
        review: "“Endurance totally saved my butt when my engine started giving me trouble. I was dreading dealing with repairs, but they made it simple. Covered everything and got me back on the road. Really didn't expect it to go so smoothly!”",
        author: "Maria S.",
    },
    {
        review: "“I've been with Endurance for over a year, and it's been solid. Anytime I've needed them, filing a claim has been a breeze. I even got a friend to sign up because it's been so reliable.”",
        author: "Derrick L.",
    },
    {
        review: "“Had my AC die in the middle of summer, and I didn't know what to do. Endurance stepped in, handled everything, and just made it easy. I love that I don't have to stress about big repairs anymore—they just take care of it.”",
        author: "Samantha K.",
    },
    {
        review: "“Endurance has been a total game-changer. I drive an older car, and every time something goes wrong, they cover it. No hoops to jump through, just straight-up help when I need it. Makes owning my car so much less of a worry.”",
        author: "Alex H.",
    },
    {
        review: "“Endurance came through when I needed them most. My alternator failed unexpectedly, and I was worried about the cost. One call, they took care of everything. It was super smooth, I didn't even feel the stress. Highly recommend them to anyone!”",
        author: "Emily W",
    },
    {
        review: "“I've had my policy with Endurance for two years, and it's been nothing but great. When my fuel pump went out, they had it covered without any hassle. I trust them completely, and they've never let me down.”",
        author: "Robert D.",
    },
    {
        review: "“My power steering went out, and I didn't know what to do. Thankfully, Endurance was there for me. They made the whole process quick and easy, and I was back on the road in no time. I can't imagine being without this coverage.”",
        author: "Chloe S.",
    },
    {
        review: "“Endurance has been a lifesaver for my family. Our car broke down on a road trip, and they took care of the repair without any issues. The peace of mind they offer is worth every cent.”",
        author: "Mark V.",
    },
    {
        review: "“I've gotta say, Endurance's customer service is awesome. I had an issue with my brakes, called them up, and they handled it fast. No nonsense, no trying to upsell me or anything. Just got it fixed and on with my day.”",
        author: "John P.",
    },
    {
        review: "“I've tried other warranty companies, but Endurance is on another level. They're clear, upfront, and whenever I've had to make a claim, it's been easy as can be. Honestly worth every penny.”",
        author: "Tina M.",
    },
    {
        review: "“I was a bit hesitant at first, but Endurance came through big time. Had a major issue with my suspension, and they covered it. Didn't cost me a cent, and I was back on the road. Really glad I went with them.”",
        author: "Liam F.",
    },
    {
        review: "“With Endurance, I actually feel like I'm covered, you know? My steering went bad, and they took care of it, no fuss. I didn't have to fight with anyone—just called them, and they handled it. It's nice to finally have a company that does what they say.”",
        author: "Nicole J.",
    },
    {
        review: "“When my car's cooling system failed, I was ready for the worst. But Endurance made it easy. They covered the repair, and I didn't have to stress about the cost. Couldn't ask for a better warranty company.”",
        author: "Ben T.",
    },
];

const TWEEN_FACTOR_BASE = 0.84;

const numberWithinRange = (number: number, min: number, max: number): number =>
    Math.min(Math.max(number, min), max);

const EmblaCarousel = () => {
    const [emblaRef, emblaApi] = useEmblaCarousel({ loop: true });
    const [selectedIndex, setSelectedIndex] = useState(0);

    const onSelect = useCallback((emblaApi: EmblaCarouselType) => {
        setSelectedIndex(emblaApi.selectedScrollSnap());
    }, []);

    const tweenFactor = useRef(0);

    const {
        prevBtnDisabled,
        nextBtnDisabled,
        onPrevButtonClick,
        onNextButtonClick,
    } = usePrevNextButtons(emblaApi);

    const setTweenFactor = useCallback((emblaApi: EmblaCarouselType) => {
        tweenFactor.current =
            TWEEN_FACTOR_BASE * emblaApi.scrollSnapList().length;
    }, []);

    const tweenOpacity = useCallback(
        (emblaApi: EmblaCarouselType, eventName?: EmblaEventType) => {
            const engine = emblaApi.internalEngine();
            const scrollProgress = emblaApi.scrollProgress();
            const slidesInView = emblaApi.slidesInView();
            const isScrollEvent = eventName === "scroll";

            emblaApi.scrollSnapList().forEach((scrollSnap, snapIndex) => {
                let diffToTarget = scrollSnap - scrollProgress;
                const slidesInSnap = engine.slideRegistry[snapIndex];

                slidesInSnap.forEach((slideIndex) => {
                    if (isScrollEvent && !slidesInView.includes(slideIndex))
                        return;

                    if (engine.options.loop) {
                        engine.slideLooper.loopPoints.forEach((loopItem) => {
                            const target = loopItem.target();

                            if (slideIndex === loopItem.index && target !== 0) {
                                const sign = Math.sign(target);

                                if (sign === -1) {
                                    diffToTarget =
                                        scrollSnap - (1 + scrollProgress);
                                }
                                if (sign === 1) {
                                    diffToTarget =
                                        scrollSnap + (1 - scrollProgress);
                                }
                            }
                        });
                    }

                    const tweenValue =
                        1 - Math.abs(diffToTarget * tweenFactor.current);

                    let max = 0.4;
                    let min = 0.2;

                    let maxMargin = 30;
                    let minMargin = 0;

                    const prev =
                        selectedIndex - 1 < 0
                            ? emblaApi.slideNodes().length - 1
                            : selectedIndex - 1;
                    const next =
                        selectedIndex + 1 > emblaApi.slideNodes().length - 1
                            ? 0
                            : selectedIndex + 1;

                    if (
                        // adjacent to the current slide
                        prev === slideIndex ||
                        next === slideIndex
                    ) {
                        max = 0.7;
                        min = 0.4;
                        maxMargin = 70;
                        minMargin = 30;
                    } else if (
                        // current slide
                        slideIndex === selectedIndex
                    ) {
                        max = 1;
                        min = 0.7;
                        maxMargin = 70;
                        minMargin = 30;
                    }

                    const opacity = numberWithinRange(
                        tweenValue,
                        min,
                        max,
                    ).toString();
                    const translateY = numberWithinRange(
                        tweenValue * 100,
                        minMargin,
                        maxMargin,
                    );

                    emblaApi.slideNodes()[slideIndex].style.opacity = opacity;
                    emblaApi.slideNodes()[
                        slideIndex
                    ].style.translate = `0 ${translateY}px`;
                });
            });
        },
        [selectedIndex],
    );

    useEffect(() => {
        if (!emblaApi) return;

        setTweenFactor(emblaApi);
        tweenOpacity(emblaApi);
        onSelect(emblaApi);
        emblaApi
            .on("reInit", setTweenFactor)
            .on("reInit", tweenOpacity)
            .on("scroll", tweenOpacity)
            .on("select", onSelect)
            .on("slideFocus", tweenOpacity);
    }, [emblaApi, tweenOpacity]);

    return (
        <div className={emblaStyles["embla"]}>
            <div className={emblaStyles["embla__viewport"]} ref={emblaRef}>
                <div className={emblaStyles["embla__container"]}>
                    {reviews.map((item, index) => (
                        <div
                            className={emblaStyles["embla__slide"]}
                            key={index}
                        >
                            <CustomerReview
                                review={item.review}
                                author={item.author}
                            />
                        </div>
                    ))}
                </div>
            </div>

            <div className={emblaStyles["embla__controls"]}>
                <div className={emblaStyles["embla__buttons"]}>
                    <PrevButton
                        onClick={onPrevButtonClick}
                        disabled={prevBtnDisabled}
                    />
                    <NextButton
                        onClick={onNextButtonClick}
                        disabled={nextBtnDisabled}
                    ></NextButton>
                </div>

                {/* <div className={emblaStyles["embla__dots"]}>
                    {scrollSnaps.map((_, index) => (
                        <DotButton
                            key={index}
                            onClick={() => onDotButtonClick(index)}
                            className={
                                emblaStyles[
                                    "embla__dot".concat(
                                        index === selectedIndex
                                            ? " embla__dot--selected"
                                            : "",
                                    )
                                ]
                            }
                        />
                    ))}
                </div> */}
            </div>
        </div>
    );
};

export default EmblaCarousel;

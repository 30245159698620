import { ReactElement, useState } from "react";
import AccordionItem from "./accordionItem";

const faqQuestions = [
    {
        title: "Is there a mileage limit for Endurance's coverage plans?",
        body: "Endurance provides coverage for vehicles up to 20 years old with no mileage restrictions, so you can enjoy protection regardless of how many miles you've driven.",
    },
    {
        title: "Does Endurance have a waiting period?",
        body: "Yes, Endurance's shortest waiting period is just 30 days and 1,000 miles, allowing you to start benefiting from your plan quickly.",
    },
    {
        title: "Can I cancel my Endurance plan?",
        body: "Yes, you can cancel your plan within 30 days for a full refund.",
    },
    {
        title: "When should I file a claim?",
        body: "If you experience a mechanical breakdown, contact Endurance's team immediately to start the claims process and get the support you need.",
    },
    {
        title: "How do I access roadside assistance?",
        body: "Endurance's 24/7 roadside assistance can be accessed easily through the Endurance Mobile app, ensuring help is always at your fingertips.",
    },
    {
        title: "How long does it take to process a claim with Endurance?",
        body: "Claim processing times can vary based on the complexity of the repair and required documentation. Endurance works diligently to expedite the process, but for specific details, it's best to contact their dedicated claims team directly.",
    },
    {
        title: "Can I choose my own repair shop with an Endurance Warranty?",
        body: "Absolutely. Endurance's coverage allows you to select your preferred repair facility, whether a trusted mechanic or dealership, giving you flexibility and control over your vehicle's repairs.",
    },
    {
        title: "What is the average monthly cost of Endurance?",
        body: "The average monthly cost of an Endurance plan can vary based on factors such as the level of coverage, vehicle make and model, and mileage. Typically, Endurance plans range from $70 to $130 per month, depending on the selected coverage and plan details. For an exact quote, it's best to contact Endurance directly or use their online tool to customize a plan that meets your needs.",
    },
];

export default function FAQ(): ReactElement {
    const [activeTab, setActiveTab] = useState(-1);

    const clickHandler = (idx: number) => {
        if (idx === activeTab) {
            setActiveTab(-1);
            return;
        }
        setActiveTab(idx);
    };

    return (
        <section className="mt-28 lg:mt-36 mb-40 lg:mb-64">
            <h2 className="text-center font-bold text-[25px] lg:text-[40px] mb-5">
                FAQ
            </h2>
            <div>
                {faqQuestions?.map(
                    (item: { title: string; body: string }, idx: number) => {
                        return (
                            <div key={idx} onClick={() => clickHandler(idx)}>
                                <AccordionItem
                                    title={item.title}
                                    body={item.body}
                                    isOpen={idx === activeTab}
                                />
                            </div>
                        );
                    },
                )}
            </div>
        </section>
    );
}

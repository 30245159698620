import styles from "./style.module.scss";
interface Props {
    title: string;
    body: string;
    isOpen: boolean;
}

const AccordionItem: React.FC<Props> = ({ title, body, isOpen }) => {
    return (
        <div className={`flex flex-col gap-2 lg:gap-3  `}>
            <div
                className={`flex py-4 lg:py-6 pl-5 lg:pl-11 pr-0  lg:pr-14 gap-1 justify-between cursor-pointer items-center  ${styles["backgroundStyle"]} `}
            >
                <p
                    className=" text-base text-left  lg:text-xl font-medium"
                    style={{ color: "#fff" }}
                >
                    {title}
                </p>
                <span
                    className={`rounded-md w-8 h-8 flex items-center justify-center mr-4 text-[30px]`}
                >
                    <div
                        className={`${styles.plus} ${
                            isOpen ? styles.minus : ""
                        }`}
                    ></div>
                </span>
            </div>
            <div
                className={`${styles.answer} ${
                    !isOpen
                        ? "h-0 overflow-hidden"
                        : "pt-3 lg:pt-6  pl-4 lg:pl-11 pr-4 lg:pr-24 pb-10 lg:pb-[70px]  min-h-3.5 overflow-auto "
                } text-base lg:text-lg font-light  text-[#333]  lg:text-justify`}
            >
                <span>{body}</span>
            </div>
        </div>
    );
};
export default AccordionItem;

import React, { ReactElement } from "react";
import { injectDoNotSellInFooter } from "@lib/sharedUtils";
import { useDomainContext } from "@hooks/useDomainContext";
import { useDispatch } from "react-redux";
import { setModal } from "@redux/shared/slices/modal";
import styles from "./style.module.scss";
export default function EnduranceWarrantyProtectFooter(): ReactElement {
    const dispatch = useDispatch();
    const { visitDetails, pageType } = useDomainContext();

    const footerLinks = [
        {
            title: "Privacy Policy",
            onClick: () =>
                dispatch(setModal({ active: true, content: "privacy" })),
        },
        {
            title: "Terms & Conditions",
            onClick: () =>
                dispatch(setModal({ active: true, content: "terms" })),
        },
    ];
    injectDoNotSellInFooter({
        footerArray: footerLinks,
        pageType: pageType,
        trafficSource: visitDetails?.trafficSource,
    });
    return (
        <footer className={styles["bgStyle"]}>
            <div className="max-w-7xl mx-auto block px-4vw lg:px-4 lg:pt-7 pt-11 lg:pb-5 pb-36">
                <p>
                    © {new Date().getFullYear()} Several Brands LLC, an
                    authorized reseller of Endurance Warranty Services LLC. All
                    other marks are the property of their respective owners.
                    Disclosure: A Vehicle Service Contract (VSC) is often
                    referred to as an "auto warranty" or an "extended car
                    warranty," but it is not a warranty. A VSC does, however,
                    provide repair coverage for your vehicle after the
                    manufacturer's car warranty expires. A VSC is a contract
                    between you and a VSC provider or administrator that states
                    what is a covered repair and what is not. Not all vehicles
                    qualify for coverage, and not all repairs may be covered by
                    the VSC you purchase. See contract for details. Not
                    available in MA. In California Endurance sells Mechanical
                    Breakdown Insurance (MBI) underwritten by Security National
                    Insurance Company and administered through Endurance
                    Warranty Insurance Services, CA License #6009544. MBI is not
                    a manufacturer's warranty; it provides repair coverage after
                    your manufacturer's warranty expires.
                </p>
                <ul className=" flex  mt-3 gap-6">
                    {footerLinks.map((item, index) => (
                        <li key={index}>
                            <a
                                className=" cursor-pointer"
                                onClick={item.onClick}
                            >
                                <span>{item.title}</span>
                            </a>
                        </li>
                    ))}
                </ul>
            </div>
        </footer>
    );
}

import * as React from "react";

const QoutesIcon = () => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="68"
        height="73"
        fill="none"
        viewBox="0 0 68 73"
    >
        <path
            fill="#FF7E1D"
            fillRule="evenodd"
            d="M13.028 22.986a14 14 0 0 1 3.302-.393c8.797 0 15.927 8.148 15.927 18.195 0 10.05-7.13 18.198-15.927 18.198S.4 50.84.4 40.788q0-.443.02-.885H.4c0-15.165 10.8-27.504 24.075-27.504v6.108c-4.306 0-8.277 1.674-11.447 4.479m35.646 0a14 14 0 0 1 3.296-.393c8.797 0 15.93 8.148 15.93 18.195 0 10.05-7.133 18.198-15.93 18.198S36.043 50.84 36.043 40.788q-.001-.443.02-.885h-.02c0-15.165 10.8-27.504 24.075-27.504v6.108c-4.309 0-8.275 1.674-11.444 4.479"
            clipRule="evenodd"
        ></path>
    </svg>
);

export default QoutesIcon;

import * as React from "react";

const FormLogo = ({ width, height }: { width: string; height: string }) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={width}
        height={height}
        fill="none"
        viewBox="0 0 165 14"
    >
        <g fill="#fff" clipPath="url(#clip0_0_365)">
            <path d="M26.067.882V9.43L15.047.689h-.54v12.907h2.345v-8.59l11.125 8.891h.456V.882zM36.903.882H30.53v12.714h6.372c5.126 0 8.322-1.89 8.322-6.357-.02-4.402-3.279-6.357-8.322-6.357m5.873 6.4c-.02 3.33-1.95 4.08-6.247 4.145h-3.653V3.073h3.653c4.42.043 6.206.966 6.247 4.21M58.902.882v7.11c-.041 2.727-1.038 3.693-4.815 3.736-3.777-.064-4.774-1.052-4.815-3.78V.882h-2.366v7.28c0 3.91 2.594 5.67 7.202 5.67 4.628 0 7.119-1.803 7.14-5.712V.882h-2.346M77.228 4.791c0-2.77-2.097-3.909-5.708-3.909h-8.177v12.714h2.366V8.743h5.126l4.089 4.853h2.8850000000000002l-4.214-5.004c2.304-.43 3.653-1.718 3.633-3.801m-11.52-1.74h5.625c2.615.043 3.487.409 3.487 1.783s-.996 1.783-3.487 1.825H65.71zM87.004.238l-8.385 13.359h2.511l1.474-2.363h8.841l1.495 2.363h2.615zm3.05 8.827h-6.101l3.03-4.832zM108.318.882V9.43L97.277.689h-.54v12.907h2.366v-8.59l11.104 8.891h.457V.882zM125.606 10.053c-.851.86-2.179 1.675-5.209 1.697-3.653-.043-5.542-1.44-5.563-4.489.021-2.963 1.931-4.424 5.563-4.467 2.781 0 4.005.601 5.043 1.525l.187.172 1.349-1.761-.187-.15c-1.494-1.354-3.528-1.912-6.372-1.912-4.482 0-8.032 2.212-8.053 6.615 0 4.488 3.55 6.55 8.053 6.55h.042c2.968 0 5.064-.666 6.517-2.083l.166-.172-1.349-1.718zM131.127 11.341V8.163h8.157v-2.17h-8.157V3.138h9.734V.882h-12.1v12.714h12.557v-2.255zM2.345 11.341V8.163h8.178v-2.17H2.366V3.138h9.713V.882H0v12.714h12.557v-2.255zM151.403 11.062c.394 0 1.163.021 1.806.021.934 0 1.868.344 3.736-1.933l-4.753.086zm6.393-5.477h-4.027l-.934 2.17 4.338-.043s2.927-.086 4.379-2.9c-.021 0-1.411.773-3.756.773"></path>
            <path d="M156.634 3.803c3.342 0 6.642-.3 7.991-2.92h-13.989l-2.594 12.713h.207l2.117.022h.187a3.76 3.76 0 0 0 2.512-1.01l-4.048.022 3.674-8.805z"></path>
        </g>
        <defs>
            <clipPath id="clip0_0_365">
                <path fill="#fff" d="M0 0h165v14H0z"></path>
            </clipPath>
        </defs>
    </svg>
);

export default FormLogo;

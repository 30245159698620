import { ReactElement, ReactNode } from "react";
import styles from "./style.module.scss";
import { useDispatch } from "react-redux";
import { setModal } from "@redux/shared/slices/modal";
import React, { useContext } from "react";
import { PlanContext } from "@components/layouts/endurancewarrantyprotectWrapper";
export default function SharedButton({
    children,
    buttonClassName,
    openCoverageModal,
    activePlan,
}: {
    children: ReactNode;
    buttonClassName?: string;
    openCoverageModal?: boolean;
    activePlan?: string;
}): ReactElement {
    const { setActivePlan } = useContext(PlanContext);
    const dispatch = useDispatch();

    const scrollToTop = () => {
        window.scroll({
            top: 0,
            left: 0,
            behavior: "smooth",
        });
    };
    const openCoverageModalHandler = () => {
        if (activePlan) {
            setActivePlan(activePlan);
        }
        dispatch(
            setModal({
                active: true,
                content: "compareCovered",
            }),
        );
    };

    return (
        <button
            onClick={() =>
                openCoverageModal ? openCoverageModalHandler() : scrollToTop()
            }
            className={` text-white lg:py-4 py-3 px-20 sm:px-28 lg:text-xl text-lg font-bold ${
                styles["animation"]
            } ${buttonClassName ? buttonClassName : ""}`}
        >
            {children}
        </button>
    );
}

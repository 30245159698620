import React from "react";
import QoutesIcon from "../icons/QoutesIcon";

export interface IUserReviewCardProps {
    review: string;
    author: string;
}

const CustomerReview = ({ review, author }: IUserReviewCardProps) => {
    return (
        <div className="flex flex-col gap-4 bg-[#F5F2ED] rounded-10 p-5 h-full pointer-events-none select-none">
            <div>
                <QoutesIcon />
            </div>
            <span className="text-base font-light"> {review}</span>
            <span>{author}</span>
        </div>
    );
};

export default CustomerReview;

import React, { ReactElement } from "react";
import styles from "./styles.module.scss";
import SharedButton from "../sharedButton";
import TicIcon from "./icons/ticIcon";
import PlansCarousel from "./plansCarousel/PlansCarousel";
export type BenefitsListItem = {
    title: string;
    slug: string;
    description: string;
    list: string[];
    list2?: string[];
};
const benefitsList: BenefitsListItem[] = [
    {
        title: "Secure plus",
        slug: "secure-plus",
        description: "affordable coverage designed for older vehicles",
        list: ["Engine", "Transmission", "A/C"],
    },
    {
        title: "Superior",
        slug: "superior",
        description:
            "Extensive coverage that protects a wide range of components",
        list: [
            "Engine",
            "Transmission",
            "A/C",
            "Fuel System",
            "Electrical",
            "Hight-Tech",
            "Options",
        ],
    },
    {
        title: "Supreme",
        slug: "supreme",
        description: "Affordable coverage designed for older vehicles",
        list: [
            "Engine",
            "Transmission",
            "A/C",
            "Air Conditioning",
            "Fuel System",
            "Electrical",
            "Hight-Tech",
            "Options",
            "Seals & Gaskets",
        ],
        list2: ["Cooling System", "Transfer Case", "Drive Axle"],
    },
];

export default function EnduranceProtectionPlans(): ReactElement {
    return (
        <section className={`${styles["bg-style"]}`}>
            <div className="pt-24 lg:pt-28 pb-20 lg:pb-28">
                <h2
                    className="font-bold text-[25px] lg:text-[40px] text-center "
                    style={{ color: "#fff" }}
                >
                    Endurance Auto Protection Plans
                </h2>
                <p
                    style={{ color: "#fff" }}
                    className=" text-base lg:text-xl text-center "
                >
                    Endurance offers a range of coverage plans to fit your
                    needs:
                </p>
                <div className="sm:flex flex-wrap gap-3 justify-center mt-14 hidden ">
                    {benefitsList.map((benefitItem) => (
                        <div key={benefitItem.title} className={styles["item"]}>
                            <h3
                                className="text-[28px] font-bold"
                                style={{ color: "#00445F" }}
                            >
                                {benefitItem.title}
                            </h3>
                            <p
                                className="text-base"
                                style={{ color: "#00445F" }}
                            >
                                {benefitItem.description}
                            </p>
                            <div className={styles["featuresStyle"]}>
                                <ul className="">
                                    {benefitItem.list.map((item, index) => (
                                        <li key={index}>
                                            <span>
                                                <TicIcon />
                                            </span>
                                            <span
                                                className="font-light"
                                                style={{ color: "#00445F" }}
                                            >
                                                {item}
                                            </span>
                                        </li>
                                    ))}
                                </ul>
                                {benefitItem.list2 && (
                                    <ul>
                                        {benefitItem.list2.map(
                                            (item, index) => (
                                                <li key={index}>
                                                    <span>
                                                        <TicIcon />
                                                    </span>
                                                    <span
                                                        className="font-light"
                                                        style={{
                                                            color: "#00445F",
                                                        }}
                                                    >
                                                        {item}
                                                    </span>
                                                </li>
                                            ),
                                        )}
                                    </ul>
                                )}
                            </div>
                            <div className={styles["buttonStyle"]}>
                                <SharedButton
                                    buttonClassName="lg:py-3 py-2  w-full"
                                    openCoverageModal
                                    activePlan={benefitItem.slug}
                                >
                                    More Details
                                </SharedButton>
                            </div>
                        </div>
                    ))}
                </div>
                <div className={`  sm:hidden mt-10 `}>
                    <PlansCarousel slides={benefitsList} />
                </div>
            </div>
        </section>
    );
}

import React, { ReactElement } from "react";
import SharedButton from "../sharedButton";
import Image from "next/image";

export default function WhyChooseEndurance(): ReactElement {
    return (
        <section className=" flex flex-col lg:flex-row   lg:mt-44 mt-32  gap-9 lg:gap-0  ">
            <div className="max-w-[575px]  mx-auto lg:mx-0  order-2  lg:mt-10 ">
                <h2 className="font-bold lg:text-[35px] text-[25px] lg:leading-[50px] leading-[32px] ">
                    Why Choose{" "}
                    <span
                        className="rounded-5 px-1"
                        style={{ backgroundColor: "#FF870D", color: "#fff" }}
                    >
                        Endurance?
                    </span>{" "}
                </h2>
                <p className="lg:mt-4 mt-3  lg:leading-[27px] leading-[23px] text-[#333]  font-light ">
                    From daily commuters to rideshare drivers, Endurance offers
                    auto protection plans for all types of vehicle owners.
                    Endurance provides coverage for cars up to 20 years old,
                    helping you prepare for breakdowns and avoiding unexpected
                    repair costs. Whether you drive a newer model or a used car
                    with over 200,000 miles, Endurance provides reliable,
                    affordable protection backed by positive reviews, an A- BBB
                    rating, and a strong industry reputation.
                </p>

                <div className="lg:mt-[50px] mt-7 text-center sm:text-left">
                    <SharedButton>Get My Free Quote</SharedButton>
                </div>
            </div>
            <div className="w-[250px] h-[250px] md:h-[476px] md:w-[476px] md:min-w-[476px] relative mx-auto  order-1   ">
                <Image
                    alt="Image"
                    src={
                        "/endurancewarrantyprotect/assets/images/whyChooseEnduranceImg.png"
                    }
                    objectFit="contain"
                    layout="fill"
                />
            </div>
        </section>
    );
}

import React, { ReactElement } from "react";
import SharedButton from "../sharedButton";
import Image from "next/image";

export default function ComprehensiveCoverage(): ReactElement {
    return (
        <section className=" flex flex-col lg:flex-row   lg:mt-36 mt-16  lg:mb-40 mb-16 ">
            <div className="max-w-[575px]  mx-auto lg:mx-0  order-2 lg:order-1">
                <h2 className="font-bold lg:text-[35px] text-[25px] lg:leading-[50px] leading-[32px] ">
                    <span
                        className="rounded-5 px-1"
                        style={{ backgroundColor: "#FF870D", color: "#fff" }}
                    >
                        Endurance
                    </span>{" "}
                    Comprehensive Coverage & Customizable Plans
                </h2>
                <p className="lg:mt-4 mt-3  lg:leading-[27px] leading-[23px]  text-[#333] font-light ">
                    For nearly 20 years, Endurance has been a trusted name in
                    vehicle service contracts (VSCs), often called “extended
                    warranties,” delivering peace of mind to drivers across the
                    country. Endurance offers plans that are comprehensive and
                    customizable, allowing you to choose your level of coverage
                    and only pay for what matters most. Enjoy benefits like 24/7
                    roadside assistance, rental car coverage, and trip
                    interruption reimbursement, plus a year of Elite Benefits
                    for extra perks like tire coverage, key fob replacement, and
                    collision discounts.
                </p>
                <p className="mt-3  lg:leading-[27px] leading-[23px]  text-[#333]  font-light">
                    When a breakdown happens, Endurance helps you find a
                    mechanic close by or lets you choose your own certified
                    repair shop. Take advantage of a 30-day money-back guarantee
                    and secure dependable, affordable coverage that will give
                    you true confidence for every drive.
                </p>
                <div className="lg:mt-[50px] mt-7 text-center sm:text-left">
                    <SharedButton>Get My Free Quote</SharedButton>
                </div>
            </div>
            <div className="w-[250px] h-[250px] md:h-[463px] md:w-[550px] md:min-w-[550px] relative mx-auto  order-1 lg:order-2  lg:mt-20">
                <Image
                    alt="Image"
                    src={
                        "/endurancewarrantyprotect/assets/images/comprehensiveCoverageImg.png"
                    }
                    objectFit="contain"
                    layout="fill"
                />
            </div>
        </section>
    );
}

import React, { ReactElement, useContext } from "react";
import { PlanContext } from "@components/layouts/endurancewarrantyprotectWrapper";
import styles from "./style.module.scss";
import TicIcon from "../enduranceProtectionPlans/icons/ticIcon";
import NonTicIcon from "./nonTicIcon";
const compareList = [
    {
        title: "Air Conditioning",
        list: [true, true, true],
    },
    {
        title: "Brakes",
        list: [true, true, true],
    },
    {
        title: "Cooling System",
        list: [false, true, true],
    },
    {
        title: "Drive Axle",
        list: [true, true, true],
    },
    {
        title: "Electrical Components",
        list: [true, true, true],
    },
    {
        title: "Engine",
        list: [true, true, true],
    },
    {
        title: "Suspension",
        list: [false, true, true],
    },
    {
        title: "Fuel System",
        list: [false, true, true],
    },
    {
        title: "Gaskets",
        list: [false, false, true],
    },
    {
        title: "Hybrid Coverage",
        list: [true, true, true],
    },
    {
        title: "Steering",
        list: [true, true, true],
    },
    {
        title: "Transfer Case",
        list: [true, true, true],
    },
    {
        title: "Transmission",
        list: [true, true, true],
    },
    {
        title: "Turbo / Supercharger",
        list: [true, true, true],
    },
    {
        title: "24/7 Roadside Assistance",
        list: [true, true, true],
    },
    {
        title: "Rental Car Benefits",
        list: [true, true, true],
    },
];

export default function CompareWhatCoveredModal(): ReactElement {
    const { activePlan } = useContext(PlanContext);
    return (
        <section className={styles["mainSectionStyle"]}>
            <div className={styles["titleWrapper"]}>
                <h2 className={`${styles["mainModalTitle"]}`}>
                    Compare what's covered
                </h2>
                <h3 className={styles["mainModalDescription"]}>
                    List of components covered by plans
                </h3>
            </div>

            <div
                className={`${styles["mainHeaderTitle"]} ${styles[activePlan]}`}
            >
                <span></span>
                <span>Secure Plus</span>
                <span>Superior</span>
                <span>Supreme</span>
            </div>
            {compareList.map((el, index) => (
                <div
                    key={index}
                    className={`${styles["rowStyle"]} ${styles[activePlan]}`}
                >
                    <span>{el.title}</span>
                    {el.list.map((feature, index) => (
                        <span key={index}>
                            {feature ? <TicIcon /> : <NonTicIcon />}
                        </span>
                    ))}
                </div>
            ))}
        </section>
    );
}

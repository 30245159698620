import React from "react";
import type { IFeature } from "./features";
// import Image from "next/image";
import styles from "./styles.module.scss";

function Card({ feature }: { feature: IFeature }) {
    return (
        <div className={styles.card}>
            <div className={styles.cardImageContainer}>
                <img src={feature.image} alt={feature.title} />
            </div>
            <h3 className={styles.cardTitle}>{feature.title}</h3>
            <p className={styles.cardDescription}>{feature.description}</p>
            <p className={styles.cardPrice}>{feature.price}</p>
        </div>
    );
}

export default Card;

import * as React from "react";

const TicIcon = () => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="15"
        height="12"
        fill="none"
        viewBox="0 0 15 12"
    >
        <path
            fill="#4AC1E0"
            fillRule="evenodd"
            d="M14.596.282a1.11 1.11 0 0 1 .111 1.607l-8.842 9.722a1.2 1.2 0 0 1-.852.388 1.2 1.2 0 0 1-.877-.333L.346 8.021a1.11 1.11 0 0 1 0-1.611 1.22 1.22 0 0 1 1.676 0l2.894 2.784L12.925.39a1.217 1.217 0 0 1 1.67-.107"
            clipRule="evenodd"
        ></path>
    </svg>
);

export default TicIcon;

import React from "react";
import EmblaCarousel from "../carousel/EmbalaCarousel";

const CustomerReviews = () => {
    return (
        <section className="mt-28 lg:mt-36 mb-40 lg:mb-64 flex flex-col lg:gap-8 overflow-auto">
            <h2 className="text-center font-extrabold text-2xl sm:text-3xl">
                Verified Customer Reviews
            </h2>
            <EmblaCarousel />
        </section>
    );
};

export default CustomerReviews;

import * as React from "react";

const EnduranceWarrantyProtectLogo = () => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="224"
        height="20"
        fill="none"
        viewBox="0 0 224 20"
    >
        <g clipPath="url(#clip0_0_335)">
            <path
                fill="#00445F"
                d="M35.388 1.26v12.211L20.427.984h-.733v18.44h3.184V7.15L37.98 19.853h.62V1.261zM50.098 1.26h-8.65v18.164h8.65c6.96 0 11.299-2.7 11.299-9.082-.029-6.29-4.452-9.081-11.299-9.081m7.974 9.143c-.028 4.756-2.649 5.83-8.481 5.922h-4.959V4.39h4.959c6.001.061 8.425 1.38 8.48 6.013M79.964 1.26v10.156c-.056 3.896-1.409 5.277-6.537 5.338-5.128-.092-6.48-1.503-6.536-5.4V1.261h-3.212v10.4c0 5.584 3.522 8.1 9.776 8.1 6.284 0 9.665-2.577 9.693-8.161V1.26h-3.184M104.842 6.844c0-3.957-2.846-5.583-7.749-5.583h-11.1v18.163h3.2110000000000003V12.49h6.96l5.551 6.934h3.9160000000000004l-5.72-7.15c3.128-.613 4.959-2.454 4.931-5.43M89.204 4.36h7.636c3.55.062 4.733.583 4.733 2.547 0 1.963-1.352 2.546-4.733 2.608h-7.636zM118.114.34l-11.383 19.083h3.409l2.001-3.375h12.003l2.029 3.375h3.55zm4.142 12.61h-8.283l4.113-6.904zM147.05 1.26v12.211L132.061.984h-.733v18.44h3.212V7.15l15.074 12.702h.62V1.261zM170.52 14.362c-1.155 1.227-2.959 2.393-7.072 2.424-4.959-.062-7.523-2.056-7.552-6.413.029-4.234 2.621-6.32 7.552-6.381 3.775 0 5.438.859 6.846 2.178l.254.246 1.831-2.516-.253-.215c-2.029-1.933-4.79-2.73-8.65-2.73-6.086 0-10.904 3.16-10.933 9.449 0 6.412 4.819 9.358 10.933 9.358h.056c4.029 0 6.875-.951 8.847-2.976l.226-.246-1.832-2.454zM178.015 16.202v-4.54h11.073v-3.1h-11.073v-4.08h13.215V1.26h-16.427v18.164h17.047v-3.222zM3.184 16.202v-4.54h11.101v-3.1H3.212v-4.08h13.186V1.26H0v18.164h17.047v-3.222z"
            ></path>
            <path
                fill="#FD7F20"
                d="M205.542 15.803c.535 0 1.577.03 2.451.03 1.268 0 2.536.491 5.072-2.76l-6.453.122zm8.678-7.824h-5.466l-1.269 3.099 5.889-.061s3.974-.123 5.945-4.142c-.028 0-1.916 1.104-5.099 1.104"
            ></path>
            <path
                fill="#FD7F20"
                d="M212.643 5.433c4.536 0 9.016-.43 10.848-4.172H204.5l-3.522 18.163h.282l2.874.03h.253c.789 0 2.17-.245 3.41-1.442l-5.495.03 4.988-12.578z"
            ></path>
        </g>
        <defs>
            <clipPath id="clip0_0_335">
                <path fill="#fff" d="M0 0h224v20H0z"></path>
            </clipPath>
        </defs>
    </svg>
);

export default EnduranceWarrantyProtectLogo;

import React, { ReactElement } from "react";
import Form from "@components/shared/form";
import styles from "./styles.module.scss";
import colors from "@styles/variables/nextautowarrantyVariables.module.scss";
import { useDomainContext } from "@hooks/useDomainContext";
import { useRouter } from "next/router";
import { finalShowForm } from "@lib/sharedUtils";
import TextBlockOffer from "@components/shared/textBlockOffer/TextBlockOffer";
import FormLogo from "./formLogo";
export default function MainBanner(): ReactElement {
    const { domain } = useDomainContext();
    const router = useRouter();

    const isFormDisplayed = finalShowForm(
        router.query.sl as string,
        domain.showForm,
        domain.defaultOffers,
    );
    return (
        <>
            {isFormDisplayed ? (
                <>
                    <div className={styles["main-banner"]}>
                        <div className="pt-9 lg:pt-20 pb-10 3xl:pb-24">
                            <Form
                                classes={{
                                    formClassName: styles["form"],
                                    stepTitleClassName: styles["step-title"],
                                    stepDescriptionClassName:
                                        styles["step-description"],
                                    formButtonClassName: styles["form-button"],
                                    formFieldClassName: styles["form-field"],
                                    formStepInnerClassName:
                                        styles["steps-inner"],
                                    fieldsClassName: {
                                        radio: styles["radio-field"],
                                        checkbox: styles["checkbox-field"],
                                        select: styles["select-field"],
                                        all: styles["field"],
                                    },
                                    fieldsWrapperClassName:
                                        styles["fields-wrapper"],
                                    tcpaClassName: styles["tcpa"],
                                    labelAsTitleClassName:
                                        styles["label-as-step-title"],
                                    fieldNoteClassName: styles["field-note"],
                                    fieldErrorMessageClassName:
                                        styles["field-error-message"],
                                    sellingPointClassName:
                                        styles["selling-point"],
                                    stepClassName: styles["step-block"],
                                }}
                                colors={{
                                    primaryColor: colors.gorseYellowOrange,
                                    progressBar: "#B8B8B8",
                                }}
                                showProgress={true}
                                goToThankYouPage={true}
                                sellingPointsSteps="FIRST_STEP"
                                dynamicThankYouFields={[
                                    "firstName",
                                    "manufacturingYear",
                                    "vehicleMake",
                                    "vehicleModel",
                                ]}
                            />
                            <div className={styles["bellowBanner"]}>
                                <span>Driven by the reliability of </span>
                                <span className="lg:hidden">
                                    <FormLogo height={"10"} width={"110"} />
                                </span>
                                <span className="hidden lg:inline">
                                    <FormLogo height={"14"} width={"165"} />
                                </span>
                            </div>
                        </div>
                    </div>
                </>
            ) : (
                <div className={`${styles["main-banner"]} removeWhiteBg`}>
                    <TextBlockOffer />
                </div>
            )}
        </>
    );
}
